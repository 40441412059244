
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import GuestLayout from "@/layout/GuestLayout.vue";
import { useStore } from "vuex";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import WellDoneBlock from "@/views/Onboarding/WellDoneBlock.vue";
//@ts-ignore
import DeepLinker from "@/utils/DeepLinker.js";

export default defineComponent({
  name: "ConfirmEmail",
  components: { WellDoneBlock, GuestLayout },
  setup: function () {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const confirmEmail = async (opt: ICrudOptions) => {
      await store.dispatch("email-confirmation/confirmation/findOne", opt);
    };

    const confirmEmailLoading = computed(() => store.getters["email-confirmation/confirmation/getIsLoading"]);
    const confirmEmailSuccess = computed(() => store.getters["email-confirmation/confirmation/getSuccess"]);
    const confirmEmailError = computed(() => store.getters["email-confirmation/confirmation/getError"]);

    const resendConfirmEmail = async (opt: ICrudOptions) => {
      await store.dispatch("email-confirmation/resendConfirmation/findOne", opt);
    };

    const resendConfirmEmailLoading = computed(() => store.getters["email-confirmation/resendConfirmation/getIsLoading"]);
    const resendConfirmEmailSuccess = computed(() => store.getters["email-confirmation/resendConfirmation/getSuccess"]);
    const resendConfirmEmailError = computed(() => store.getters["email-confirmation/resendConfirmation/getError"]);

    const linker = ref<any>(null);
    const linkerLoading = ref(false);

    onMounted(() => {
      confirmEmailAction();

      // linker.value = new DeepLinker({
      //   onIgnored: function () {
      //     //browser failed to respond to the deep link
      //     confirmEmailAction();
      //     linkerLoading.value = false;
      //   },
      //   onFallback: function () {
      //     //dialog hidden or user returned to tab
      //     linkerLoading.value = false;
      //   },
      //   onReturn: function () {
      //     //user returned to the page from the native app
      //     linkerLoading.value = false;
      //   },
      // });
      // tryToOpenDriveBuzz();
    });

    const confirmEmailAction = async () => {
      await confirmEmail({
        resource: `/students/confirm-email?email=${route.query.email}&code=${route.query.code}`,
        descriptionField: "",
      });
      if (confirmEmailSuccess.value) {
        await router.push({ name: "Login" });
      }
    };

    const tryToOpenDriveBuzz = () => {
      if (route.query.internalRedirect) {
        confirmEmailAction();
        linkerLoading.value = false;
      } else {
        const exportUrl = `drivebuzz://students/confirm-email?email=${route.query.email}&code=${route.query.code}`;
        linker.value?.openURL(exportUrl);
      }
    };

    const onResendEmail = async () => {
      await resendConfirmEmail({
        resource: `/students/send-confirmation-email?email=${route.query.email}`,
        descriptionField: "",
      });

      if (resendConfirmEmailSuccess.value) {
        await router.push({ name: "ResendDone" });
      }
    };

    const goHome = () => {
      router.push({ name: "GuestHome" });
    };

    onUnmounted(() => {
      linker.value?.destroy();
    });

    const loading = computed(() => linkerLoading.value || confirmEmailLoading.value);

    return {
      confirmEmailError,
      onResendEmail,
      resendConfirmEmailLoading,
      goHome,
      loading,
    };
  },
});
